import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useState, cloneElement } from "react";
// import { useInViewEffect } from "react-hook-inview";
// import Slide from "react-reveal/Slide";
import Slider from "react-slick";

import Tile from "./tile";

import * as sharedStyles from "app/styles/shared";
import * as styles from "./carousel.styles";

const S = {
  ...sharedStyles,
  ...styles,
};

const defaultSettings = {
  dots: true,
  infinite: true,
  edgeFriction: 0.05,
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  adaptiveHeight: true,
};

const Carousel = ({ headline, items, width, Tile, settings, accent, Btn }) => {
  const [maxH, setMaxH] = useState(0);
  const [flipped, setFlipped] = useState(false);
  const [hideArrows, setHideArrows] = useState(true);
  const CarouselBtn = (props) => <S.CrslBtn hidden={hideArrows} {...props} />;
  // const [isVisible, setIsVisible] = useState(true); // To fix. IE11 problems.

  // const ref = useInViewEffect(
  //   ([entry], observer) => {
  //     if (entry.isIntersecting) {
  //       observer.unobserve(entry.target);
  //     }
  //     setIsVisible(entry.isIntersecting);
  //   },
  //   { threshold: 0.5 }
  // );

  const measuredRef = (node) => {
    const slideHeight = node !== null && node.getBoundingClientRect().height;
    if (slideHeight && slideHeight > maxH) {
      setMaxH(slideHeight);
    }
  };

  const slickSettings = {
    ...defaultSettings,
    ...settings,
    nextArrow: <CarouselBtn />,
    prevArrow: <CarouselBtn />,
  };

  return (
    <S.CrslBox css={S.clipBox}>
      <S.CrslContent as="section">
        {headline && (
          <S.Container>
            <S.CrslHeadline as="h2">{headline}</S.CrslHeadline>
          </S.Container>
        )}
      </S.CrslContent>
      <S.CrslWrap
        onMouseEnter={() => setHideArrows(false)}
        onMouseLeave={() => setHideArrows(true)}
        // className={!isVisible && "out-of-viewport"}
      >
        <S.CarouselSizer minH={maxH}>
          <Slider {...slickSettings}>
            {items.map((item, idx) => (
              <div
                style={{ width }}
                key={idx}
                ref={measuredRef}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.target.blur();
                }}
                role="button"
                tabIndex={0}
              >
                {cloneElement(Tile, {
                  accent: accent,
                  ...item,
                  flipped: item.id === flipped,
                  setFlipped,
                })}
              </div>
            ))}
          </Slider>
        </S.CarouselSizer>
      </S.CrslWrap>
      {Btn && cloneElement(Btn)}
    </S.CrslBox>
  );
};

Carousel.defaultProps = {
  items: [],
  Tile: <Tile />,
  width: 360,
  settings: {},
};

// export default memo(Carousel);
export default Carousel;
