import React from "react";
import _get from "lodash.get";
import { useEndDate } from "app/hooks/use-end-date";
import SiteData from "app/content/_site-settings.json";

import * as S from "app/components/challenge-accept-btn.styles";

import Btn from "app/components/btn";
import ShareBtn from "app/components/share-button";

const ChallengeAcceptBtn = () => {
  const { contestOver } = useEndDate();

  return (
    <S.CABOuter>
      <S.CABInner>
        {!contestOver && (
          <Btn {...(_get(SiteData, "challenge_cta.btn") || {})} />
        )}
        <ShareBtn
          round
          text={null}
          style={!contestOver ? { marginLeft: 20 } : undefined}
        />
      </S.CABInner>
    </S.CABOuter>
  );
};

export default ChallengeAcceptBtn;
