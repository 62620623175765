import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { rem } from "../styles/utils";
import { desktop } from "../styles/breakpoints";
import { Circle } from "../styles/shared";

// "Case Study" tile styles
const largeImg = css`
  width: ${rem(180)};
  height: ${rem(180)};
  margin-bottom: ${rem(30)};

  @media (${desktop}) {
    margin-bottom: ${rem(60)};
  }
`;

// "Sponsor" tile styles
const smallImg = css`
  width: ${rem(120)};
  height: ${rem(120)};
  margin-bottom: ${rem(30)};

  @media (${desktop}) {
    margin-bottom: ${rem(40)};
  }
`;

// "stat" tile styles
const xsmallImg = css`
  width: ${rem(90)};
  height: ${rem(90)};
  margin-bottom: ${rem(20)};

  @media (${desktop}) {
    margin-bottom: ${rem(40)};
  }
`;

const imgStyle = ({ variant: v }) => css`
  margin: 0 auto;
  ${v === "large" && largeImg};
  ${v === "small" && smallImg};
  ${v === "xsmall" && xsmallImg};
`;

export const ImgWrap = styled.div`
  position: relative;
  ${imgStyle};
`;

export const imgEl = css`
  max-width: 100%;
  max-height: 100%;
`;

const accentTop = css`
  top: 0;
  left: 0;
  transform: translate3d(-50%, 0, 0);

  @media (${desktop}) {
    transform: translate3d(-50%, ${rem(-10)}, 0);
  }
`;

const accentBot = css`
  bottom: 0;
  right: 0;
  transform: translate3d(${rem(10)}, ${rem(10)}, 0);

  @media (${desktop}) {
    width: ${rem(120)};
    height: ${rem(120)};
    transform: translate3d(50%, 0, 0);
  }
`;

const accentPos = ({ top, bot }) => css`
  ${top && accentTop};
  ${bot && accentBot};
`;

const accentSize = css`
  width: ${rem(80)};
  height: ${rem(80)};
  padding: 0;
`;

export const ImgAccent = styled(Circle)`
  position: absolute;
  ${accentSize};
  ${accentPos};
`;

export const videoBG = css`
  width: 100%;
  height: 0;
  padding-top: 100%;
`;
