import styled from "@emotion/styled";
// import { css } from "@emotion/react";
import { desktop } from "../styles/breakpoints";
import { rem, fontSize, fontWeights } from "../styles/utils";

import Image from "./image";
import { Container, innerContainer } from "../styles/shared";

export const QuoteImg = styled(Image)`
  border-radius: 100%;
  margin-bottom: ${rem(40)};
`;

export const QuoteBox = styled(Container)`
  ${innerContainer};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: ${rem(50)};
  padding-bottom: ${rem(45)};
  margin-top: ${rem(50)};
  margin-bottom: ${rem(15)};
`;

export const QuoteTxt = styled.p`
  width: 100%;
  ${fontSize(18, 28)};
  font-style: normal;
  font-weight: ${fontWeights.book};
  margin-top: 0;
  margin-bottom: ${rem(30)};

  @media (${desktop}) {
    ${fontSize(24, 36)};
  }
`;

export const QuoteCite = styled.cite`
  width: 100%;
  ${fontSize(18, 20)};
  font-style: normal;
`;
