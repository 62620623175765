import React, { useState } from "react";
import { graphql } from "gatsby";
import { FileIcon, PlayIcon } from "./icons";

import Btn from "app/components/btn";

import * as stylesShared from "app/styles/shared";
import * as styles from "app/components/resources-list.styles";

const S = {
  ...stylesShared,
  ...styles,
};

const ResourceItem = ({ download, image, title, link, iconType }) => {
  const Icon = iconType === "video" ? PlayIcon : FileIcon;
  return (
    <S.RLItem>
      <S.RLContent
        href={(download && download.publicURL) || link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          window !== "undefined" &&
            !!window.gtag &&
            window.gtag("event", "challenge_resource_view", {
              event_category: "resource",
              event_label: (download && download.publicURL) || link,
            });
        }}
      >
        {image && <S.RlImg image={image} />}
        {!image && <Icon css={S.rlIcon} />}
        <S.RLTxtBox>
          <S.RLText>{title}</S.RLText>
        </S.RLTxtBox>
      </S.RLContent>
    </S.RLItem>
  );
};

const ResourcesList = ({ title, text, items }) => {
  const [showAll, setShowAll] = useState(items.length < 6);
  const shown = showAll ? items : items.slice(0, 6);

  return (
    <S.RLBox as="section">
      <S.InnerContainer>
        <S.RLTitle as="h2">{title}</S.RLTitle>
        {text && <p css={[S.bodyTxt, S.centerTxt]}>{text}</p>}
        <S.RLList>
          {shown.map((resource, idx) => (
            <ResourceItem key={`-${idx}`} {...resource} />
          ))}
        </S.RLList>

        {!showAll && (
          <Btn
            center
            thin
            onClick={() => setShowAll(true)}
            css={S.rlBtn}
            label="See More"
          />
        )}
      </S.InnerContainer>
    </S.RLBox>
  );
};

export default ResourcesList;

export const resourcesFragment = graphql`
  fragment Resources on ChallengesJson {
    resources {
      link
      title
      iconType
    }
  }
`;

export const resourcesField = {
  label: "Resources",
  name: "resources",
  widget: "list",
  fields: [
    {
      label: "Title",
      name: "title",
      widget: "string",
    },
    {
      label: "Link",
      name: "link",
      widget: "string",
    },
    {
      label: "Image",
      name: "image",
      widget: "image",
      required: false,
    },
    {
      label: "Icon Type",
      name: "iconType",
      widget: "select",
      options: ["video", "text"],
      default: "text",
    },
  ],
};
