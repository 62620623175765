import React from "react";

import VideoBG from "app/components/video-background";
import Image from "app/components/image";

import * as stylesShared from "app/styles/shared";
import * as styles from "app/components/circle-image.styles";

const S = {
  ...stylesShared,
  ...styles,
};

const CircleImage = ({ accent, img, videoSrc, size, color, ...props }) => (
  <S.ImgWrap {...props} variant={size}>
    {accent && <S.ImgAccent color={color} top />}
    {accent && <S.ImgAccent color={color} bot />}
    {videoSrc && <VideoBG src={videoSrc} circle css={S.videoBG} />}
    {!videoSrc && img && (
      <div css={[S.circleImg, S.imgEl]}>
        <Image image={img} />
      </div>
    )}
    {!videoSrc && !img && <S.Circle size={200} />}
  </S.ImgWrap>
);

CircleImage.defaultProps = {
  size: "small",
  accent: false,
};

export default CircleImage;
