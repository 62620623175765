import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { rem } from "../styles/utils";
import { desktop } from "../styles/breakpoints";
import {
  h3,
  h4,
  h6,
  bodyTxt,
  StatTxt,
  flexCenterAll,
  Container,
} from "../styles/shared";
import Btn from "./btn";

export const Tile = styled.figure`
  ${flexCenterAll};
  flex-direction: column;
  justify-content: flex-start;
  width: ${rem(300)};
  padding: ${rem(30)} 0;
  height: 100%;
  margin: 0 auto;
`;

// "Stat" tile styles
const xlargeTitle = css`
  ${StatTxt};

  @media (${desktop}) {
    margin-bottom: ${rem(30)};
  }
`;

// "Case Study" tile styles
const largeTitle = css`
  ${h4};
  text-transform: uppercase;

  @media (${desktop}) {
    ${h3};
  }
`;

// "Sponsor" tile styles
const smallTitle = css`
  ${h4};
  text-transform: uppercase;

  @media (${desktop}) {
    margin-bottom: ${rem(10)};
  }
`;

const titleStyles = ({ variant: v }) => css`
  ${v === "small" && smallTitle}
  ${v === "large" && largeTitle}
  ${v === "xlarge" && xlargeTitle}
  margin-top: 0;
  margin-bottom: ${rem(20)};
`;

export const TileTitle = styled.h3`
  ${titleStyles};
`;

export const TileText = styled.p`
  ${bodyTxt};
  width: 100%;
  margin-top: 0;

  @media (${desktop}) {
    ${h6};
  }
`;

export const TileBtn = styled(Btn)`
  margin-top: ${rem(30)};
  margin-bottom: ${rem(40)};

  @media (${desktop}) {
    margin-top: auto;
  }
`;

export const DoubleWrap = styled(Container)`
  display: flex;
  justify-content: center;
`;

export const DoubleItem = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
`;

export const doubleTile = css`
  margin: 0;
`;
