import styled from "@emotion/styled";
// import { css } from "@emotion/react";

import { colors, rem } from "../styles/utils";
import { desktop } from "../styles/breakpoints";
import { H1, centerTxt } from "../styles/shared";
import arrowIcon from "../images/icon-arrow.svg";

export const CrslBox = styled.div`
  position: relative;
  margin: ${rem(60)} auto;

  @media (${desktop}) {
    margin: ${rem(80)} auto;
  }
`;

export const CrslHeadline = styled(H1)`
  ${centerTxt};
  margin: 0 auto ${rem(30)};
`;

export const CrslContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;

  @media (${desktop}) {
    margin: 0 auto;
  }
`;

export const CrslWrap = styled.div`
  position: relative;
  margin-bottom: ${rem(80)};

  .slick-list {
    transition: transform 0.5s linear;
    transition-delay: 0.2s;
    transform: translate3d(0, 0, 0);
  }

  &.out-of-viewport .slick-list {
    transform: translate3d(100%, 0, 0);
  }

  /* .slick-track {
    display: flex;
  } */

  .slick-dots {
    bottom: ${rem(-40)};

    li {
      position: relative;
      width: ${rem(8)};
      height: ${rem(8)};
      padding: 0;
      margin: 0 ${rem(4)};

      button {
        position: absolute;
        width: ${rem(8)};
        height: ${rem(8)};
        line-height: 1;
        padding: 0;
        margin: 0;

        &::before {
          width: ${rem(8)};
          height: ${rem(8)};
          font-size: ${rem(8)};
        }
      }
    }
  }
`;

export const CrslBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${rem(100)};
  width: ${rem(50)};
  padding: 0;
  background-color: ${colors.black} !important;
  background-image: url(${arrowIcon}) !important;
  background-size: auto 50% !important;
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  border: 0;
  border-bottom-left-radius: ${rem(50)};
  border-top-left-radius: ${rem(50)};
  cursor: pointer;
  z-index: 10;

  &[hidden] {
    visibility: hidden;
  }

  &::before {
    content: "";
  }

  &.slick-next {
    right: 0;
  }

  &.slick-prev {
    left: 0;
    transform: translate3d(0, -50%, 0) scaleX(-1);
  }
`;

export const CarouselSizer = styled.div`
  position: relative;
  min-height: ${({ minH }) => rem(minH)};
  display: flex-start;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  /* This positions the carousel nav against the sizer container */
  .slick-slider {
    position: static;
  }
`;
