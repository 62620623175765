import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import SiteData from "app/content/_site-settings.json";

import { ArticleTitle, ArticleBody } from "app/components/article";
import { btnField } from "app/components/btn";
import Banner from "app/components/banner";
import ChallengeAcceptBtn from "app/components/challenge-accept-btn";
import ChallengeCardList from "app/components/challenges-card-list";
import Finalists, { finalistsField } from "app/components/finalists";
import GetStarted from "app/components/get-started";
import Layout from "app/components/layout";
import QuoteBlock, { testimonialField } from "app/components/quote-block";
import ResourcesList, { resourcesField } from "app/components/resources-list";
import SEOMeta, { seoField } from "app/components/seo";
import SponsorBlock, { sponsorField } from "app/components/sponsor-block";
import VideoHeader from "app/components/video-header";

import * as S from "app/styles/shared";

export const getPageTitle = ({ title, sdg }) =>
  `${title}, SDG ${sdg.frontmatter.sdg_id}`;

const Challenge = ({
  color,
  finalistEntries,
  html,
  resources,
  slug,
  sponsor,
  summary,
  title,
  tract,
  testimonial,
  videoImage,
  videoUrl,
  ...props
}) => {
  const challenges_banner = _get(SiteData, "challenges_banner");
  const sdgId = _get(props, "sdg.sdg_id")
    ? `, SDG ${_get(props, "sdg.sdg_id")}`
    : ``;

  return (
    <Layout pageTitle={`${title}${sdgId}`}>
      <SEOMeta title={title} {..._get(props, "seo")} />
      <article>
        <Banner
          {...challenges_banner}
          text={_get(challenges_banner, "text", "").replace(
            "%CHALLENGE_NAME%",
            title
          )}
        />
        <VideoHeader
          videoUrl={videoUrl}
          image={videoImage || _get(props, "sdg.videoImage")}
          logo={_get(props, "sponsor.logo")}
          color={_get(props, "sdg.color")}
        />
        <S.InnerContainer>
          <ArticleTitle title={summary} />
          <S.Tract>{tract}</S.Tract>
        </S.InnerContainer>
        <ChallengeAcceptBtn />
        <S.InnerContainer>
          <ArticleBody html={html} />
        </S.InnerContainer>
        <SponsorBlock {...sponsor} />
        <ChallengeAcceptBtn extraSpace />
        {resources && (
          <ResourcesList title="Explore this challenge" items={resources} />
        )}
        <QuoteBlock {...testimonial} />
        <Finalists challengeName={title} items={finalistEntries} />
        <GetStarted
          btn={_get(SiteData, "challenge_cta.btn")}
          title={
            _get(props, "ctaTitle") || _get(SiteData, "challenge_cta.title")
          }
        />
        <ChallengeCardList title={"More Challenges"} excluded={[slug]} />
      </article>
    </Layout>
  );
};

export default Challenge;

export const challengeFragment = graphql`
  fragment Challenge on ChallengesJson {
    title
    tract
    slug
    summary
    cardBack
    videoUrl
    videoImage {
      childImageSharp {
        gatsbyImageData(width: 640, height: 360)
      }
    }
    cardImage {
      childImageSharp {
        gatsbyImageData(width: 300, height: 360)
      }
    }
    sdg {
      ...SDG
      videoImage: coverImage {
        childImageSharp {
          gatsbyImageData(width: 640, height: 360)
        }
      }
    }
    html: body
    ctaTitle
    ...Sponsor
    ...Resources
    ...Testimonials
    ...Finalists
  }
`;

export const challegeCTAField = {
  label: "Challenge CTA Banner",
  name: "challenge_cta",
  widget: "object",
  fields: [
    {
      label: "Title",
      name: "title",
      widget: "string",
    },
    btnField,
  ],
};

export const challegeFields = [
  {
    label: "Template",
    name: "template",
    widget: "hidden",
    default: "challenge",
  },
  seoField,
  {
    label: "SDG",
    name: "sdg",
    multiple: false,
    widget: "relation",
    collection: "sdgs",
    searchFields: ["sdg_name", "sdg_id"],
    valueField: "sdg_id",
    displayFields: ["sdg_id"],
  },
  {
    label: "Title",
    name: "title",
    widget: "string",
  },
  {
    label: "Tract",
    name: "tract",
    widget: "select",
    options: ["Imagination League (ages 5 - 12)", "Impact League (ages 13-24)"],
  },
  {
    label: "Slug",
    name: "slug",
    widget: "string",
  },
  {
    label: "Summary",
    name: "summary",
    widget: "text",
    required: false,
  },
  {
    label: "Card Back",
    name: "cardBack",
    widget: "text",
    required: false,
  },
  {
    label: "Challenge Video",
    name: "videoUrl",
    widget: "string",
    required: false,
  },
  {
    label: "Challenge Image",
    name: "videoImage",
    widget: "image",
    required: false,
  },
  {
    label: "Challenge Card Image",
    name: "cardImage",
    widget: "image",
    required: false,
    hint: "This will overide the challenge SDG image",
  },
  sponsorField,
  resourcesField,
  testimonialField,
  finalistsField,
  {
    label: "Content",
    name: "body",
    widget: "markdown",
    required: false,
  },
  {
    label: "Challenge Title",
    name: "ctaTitle",
    widget: "string",
    hint: `Leave blank to use the default text: "${_get(
      SiteData,
      "challenge_cta.title"
    )}"`,
    required: false,
  },
];
