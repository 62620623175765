import styled from "@emotion/styled";
// import { css } from "@emotion/react";
import { Container, flexCenterAll } from "../styles/shared";

import { rem } from "../styles/utils";

export const CABOuter = styled(Container)`
  display: block;
  ${flexCenterAll};
  margin: ${rem(60)} auto;
`;

export const CABInner = styled(Container)`
  display: flex;
  margin: 0;
  padding: 0;
`;
