import React from "react";
import { graphql } from "gatsby";

import * as SS from "../styles/shared";
import * as S from "./quote-block.styles";

const QuoteBlock = ({ photo, quote, name, title }) => (
  <S.QuoteBox as="blockquote">
    {photo && <S.QuoteImg image={photo} />}
    <S.QuoteTxt>{quote}</S.QuoteTxt>
    <S.QuoteCite>
      <b css={SS.bold}>{name}</b> / {title}
    </S.QuoteCite>
  </S.QuoteBox>
);

export default QuoteBlock;

export const testimonialsFragment = graphql`
  fragment Testimonials on ChallengesJson {
    testimonial {
      photo {
        childImageSharp {
          gatsbyImageData(
            width: 120
            height: 120
            transformOptions: { grayscale: true }
          )
        }
      }
      quote
      name
      title
    }
  }
`;

export const testimonialField = {
  label: "Testimonial",
  name: "testimonial",
  widget: "object",
  fields: [
    {
      label: "Name",
      name: "name",
      widget: "string",
      required: false,
    },
    {
      label: "Title",
      name: "title",
      widget: "string",
      required: false,
    },
    {
      label: "Quote",
      name: "quote",
      widget: "text",
      required: false,
    },
    {
      label: "Photo",
      name: "photo",
      widget: "image",
      required: false,
    },
  ],
};
