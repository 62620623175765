import React, { memo } from "react";
import { graphql } from "gatsby";

import * as S from "./tile.styles";

import CircleImage from "./circle-image";

const Tile = ({
  accent,
  className,
  color,
  cta,
  img,
  imgSize,
  name,
  title,
  text,
  videoSrc,
  variant,
}) => (
  <S.Tile className={className}>
    <CircleImage
      color={color}
      accent={accent}
      img={img}
      videoSrc={videoSrc}
      size={imgSize || variant}
    />
    <S.TileTitle variant={variant} as="h3">
      {title || name}
    </S.TileTitle>
    <S.TileText as="p">{text}</S.TileText>
    {cta && <S.TileBtn {...cta} />}
  </S.Tile>
);

Tile.defaultProps = {
  variant: "large",
};

export const tileFragment = graphql`
  fragment WinnerTile on ContentJsonTile {
    name
    text
    img {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          transformOptions: { grayscale: true }
        )
      }
    }
    color
  }
`;

export default memo(Tile);
// export default Tile;

export const DoubleTile = ({ winners, accent }) => (
  <S.DoubleWrap>
    <S.DoubleItem>
      <Tile {...winners[0]} accent={accent} css={S.doubleTile} />
    </S.DoubleItem>
    <S.DoubleItem>
      {(winners[1] && (
        <Tile {...winners[1]} accent={accent} css={S.doubleTile} />
      )) || <></>}
    </S.DoubleItem>
  </S.DoubleWrap>
);
