import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { desktop } from "../styles/breakpoints";
import { rem, colors } from "../styles/utils";

import { sectionTxtCenter } from "../styles/shared";
import Image from "./image";

const { black, white, mint } = colors;

export const RLTitle = styled.h1`
  ${sectionTxtCenter};
  margin: 0 auto ${rem(30)};
  text-align: center;
`;

export const RLBox = styled.div`
  margin: 0 auto;
  padding: ${rem(40)} 0 ${rem(60)};
  overflow: hidden;
  background-color: ${mint};

  @media (${desktop}) {
    padding: ${rem(60)} 0 ${rem(120)};
  }
`;

export const RLList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0;
  padding: 0;

  @media (${desktop}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const RLItem = styled.li`
  display: flex;
  width: 100%;
  margin: ${rem(15)} auto;

  @media (${desktop}) {
    width: calc(100% / 3);
    max-width: ${rem(245)};
    margin: ${rem(30)} 0;
  }
`;

export const RLContent = styled.a`
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: flex-start;
`;

export const RLTxtBox = styled.span`
  display: block;
  font-size: ${rem(14)};
  line-height: ${22 / 14};
  width: calc(100% - ${rem(80)});

  @media (${desktop}) {
    padding-bottom: ${rem(12)};
  }
`;

export const RLText = styled.span`
  display: inline;
  border-bottom: ${rem(2)} solid ${black};
`;

export const RlImg = styled(Image)`
  display: block;
  width: ${rem(60)} !important;
  height: ${rem(60)} !important;
  border-radius: 100%;
  margin-right: ${rem(20)};
  overflow: hidden;
`;

export const rlIcon = css`
  display: block;
  width: ${rem(60)} !important;
  height: ${rem(60)} !important;
  border-radius: 100%;
  margin-right: ${rem(20)};
  overflow: hidden;
  background-color: ${black};
  color: ${white};
`;

export const rlBtn = css`
  margin-top: ${rem(40)};
`;
