import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import ChallengeTemplate from "app/templates/challenge";

const ChallengePage = ({ data, ...props }) => (
  <ChallengeTemplate {..._get(data, "challengesJson")} {...props} />
);

export default ChallengePage;

export const query = graphql`
  query ($id: String) {
    challengesJson(id: { eq: $id }) {
      ...Challenge
    }
  }
`;
